import React from 'react'
import PropTypes from 'prop-types'
import { insideCircle } from 'geolocation-utils'

import './LocationAwareContent.css'

const LOCATION_FETCH_INTERVAL = 10000

const propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  continueText: PropTypes.string,
  at: PropTypes.string,
  center: PropTypes.shape({ latitude: PropTypes.number, longitude: PropTypes.number }),
  radius: PropTypes.number,
  forceComplete: PropTypes.bool,
  backDisabled: PropTypes.bool,
  onContinue: PropTypes.func,
  onBack: PropTypes.func,
}

class LocationAwareContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      latitude: null,
      longitude: null,
    }
    this.watchListener = null
  }

  componentDidMount() {
    this.watchListener =this.getLocation()
  }

  componentWillUnmount() {
    if (this.watchListener) {
      navigator.geolocation.clearWatch(this.watchListener)
    }
  }

  getLocation = () => {
    return navigator.geolocation.watchPosition(({ coords }) => {
      this.setState({ latitude: coords.latitude, longitude: coords.longitude })
    }, null, { enableHighAccuracy: true, maximumAge: LOCATION_FETCH_INTERVAL, })
  }

  render() {
    const { title, content, continueText, at, center, radius, forceComplete, backDisabled, onContinue, onBack } = this.props
    const { latitude, longitude } = this.state
    const inCirle = forceComplete || (latitude && longitude && insideCircle({ latitude, longitude }, center, radius))

    return (
      <div className="LAC nes-container with-title is-centered is-rounded">
        <p className="title">
          {title}
        </p>
        <div className="content">
          <div className="main-content">{content}</div>
          <div className={inCirle && "in-circle"}>
            <div className="nes-text is-success at">{at}</div>
          </div>
        </div>
        <div className={inCirle && "in-circle"}>
          <div className="buttons">
            {!backDisabled && <button type="button" className="nes-btn" disabled={backDisabled} onClick={onBack}>
              Back
            </button>}
            <button type="button" className="nes-btn is-primary continue" disabled={!inCirle} onClick={onContinue}>
              {continueText}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

LocationAwareContent.propTypes = propTypes

export default LocationAwareContent
