import React from 'react'
import './App.css'

import LocationAwareContent from './LocationAwareContent'
import Clues from './Clues'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      locationEnabled: false,
      currStep: 0,
      maxStep: 0,
    }
  }

  componentDidMount = () => {
    this.loadState()
  }

  loadState = () => {
    const locationEnabled = window.localStorage.getItem('locationEnabled') === 'true'
    const currStep = parseInt(window.localStorage.getItem('currStep') || '0')
    const maxStep = parseInt(window.localStorage.getItem('maxStep') || '0')
    this.setState({ locationEnabled, currStep, maxStep })
  }

  enableLocation = () => {
    navigator.geolocation.getCurrentPosition(() => {
      window.localStorage.setItem('locationEnabled', 'true')
      this.setState({ locationEnabled: true })
    })
  }

  onContinue = () => {
    const { currStep, maxStep } = this.state
    const nextStep = Math.min(currStep + 1, Clues.length - 1)
    const nextMax = Math.max(nextStep, maxStep)

    window.localStorage.setItem('currStep', nextStep)
    window.localStorage.setItem('maxStep', nextMax)
    this.setState({ currStep: nextStep, maxStep: nextMax })
  }

  onBack = () => {
    const { currStep } = this.state
    const nextStep = Math.max(currStep - 1, 0)

    window.localStorage.setItem('currStep', nextStep)
    this.setState({ currStep: nextStep })
  }

  render() {
    const { locationEnabled, currStep, maxStep } = this.state
    const clue = Clues[currStep]

    return (
      <div className="App">
        <div className="body">
          {!locationEnabled &&
            <header className="App-header">
              <div>Hello there my love! To get started, enable location access by clicking here:</div>
              <div>
                <button style={{ margin: 24 }} type="button" className="nes-btn is-primary" onClick={this.enableLocation}>
                  Enable Location
                </button>
              </div>
            </header>
          }
          {
            locationEnabled &&
              <LocationAwareContent
                title={clue.title}
                content={clue.content}
                continueText={clue.continue}
                at={clue.at}
                center={clue.location}
                radius={100}
                forceComplete={currStep < maxStep}
                backDisabled={currStep === 0}
                onContinue={this.onContinue}
                onBack={this.onBack}
              />
          }
        </div>
      </div>
    )
  }
}

export default App
