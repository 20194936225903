import React from 'react'

import { J, A } from './Letters'

export const Clues = [
  {
    title: 'Home',
    content: <div><p>"Why is my alarm going off on the weekend" <J /> asks herself. "Wait a second, where's <A />? Mooooooch, where did your dad go huh girly?"</p>

    <p><J /> looks around but <A /> is nowhere to be found; in fact, she hasn't seem him since last night. "Hmm, let me just text him to make sure he's alright"</p></div>,
    at: 'When you\'re at the right spot, this green text will appear and you\'ll be able to continue to the next clue!',
    continue: "Where the heck are you?!",
    location: { latitude: 37.7978057, longitude: -122.4135908 },
  }, {
    title: 'Let\'s get going',
    content: <div><p>Before she can hit send, a cryptic text comes through which reads: "Follow the breadcrumbs!" Looking around, < J/> does indeed spot some crumbs; they don't look too fancy, but they are definitely soft.</p>

    <p>Good thing <J /> and <A />'s favorite spot reopened; <J /> quickly gets ready and heads over there to see what happened to <A />.</p></div>,
    at: 'Fancy Wheatfield Bakery is the right spot but he\'s not here!',
    continue: 'Continue',
    location: { latitude: 37.798506, longitude: -122.408618 },
  }, {
    title: 'A Deal with...',
    content: <div><p><J /> vaguely remembers <A /> saying it's been a while since they visited a certain cocktail spot. Maybe he is hiding out in the secret room downstairs; it's always a favorite for out of town guests!</p></div>,
    at: 'J arrives at Devil\'s Acre but A isn\'t here either! Might be a little early for a drink anyways...',
    continue: 'Next stop...',
    location: { latitude: 37.797662, longitude: -122.406143 },
  }, {
    title: 'All tired out',
    content: <div><p><J /> can't believe <A /> would just disappear like this, not to mention give her some cryptic clues and make her walk to random spots in the city!</p>

    <p>She needs a jolt of caffeine, but walking back up that hill seems daunting. Maybe she can head towards a spot near the water instead...</p></div>,
    at: 'Yum, a nice, cold Philtered Soul, what else could you ask for? Maybe for A to make an appearance...',
    continue: 'Keep on moving',
    location: { latitude: 37.794338, longitude: -122.395379 },
  }, {
    title: 'Over the river...',
    content: <div><p>Dejected at not finding <A />, <J /> heads out the door with her coffee.</p>

    <p>Just as she passes the entrance to BART, she notices a discarded Philz cup with a pickup label on it. She doesn't want to touch the trash but she can just barely make out "<A />" on it.</p>

    <p>The station announcer mumbles something about "next train to Berkeley in...". Perhaps <A /> got confused and headed to an old home across the bay.</p></div>,
    at: 'Good ol 2161 Allston Way. 3 years living here brings back a lot of memories!',
    continue: 'Next up...',
    location: { latitude: 37.869714, longitude: -122.266484 },
  }, {
    title: 'Great Ideas',
    content: <div><p>Seems like Allston Place keeps getting upgrades. It's a shame the courtyard wasn't around when <J /> and <A /> lived there, but they still had a wonderful time anyways.</p>

    <p>In fact, this is where their relationship started! Unfortunately, <A /> couldn't have gotten inside since he doesn't have a key anymore.</p>

    <p><J /> imagines <A /> must be hungry at this point; she recalls a place nearby that had a great deal on weekday lunch, but only if <J /> and <A /> were willing to splurge a bit on their college budget!</p></div>,
    at: 'Mmm Great China was the best! But J doesn\'t have any time to stop, she has to keep moving before A gets too far.',
    continue: 'Wish I could stop for those prawns tho',
    location: { latitude: 37.867710, longitude: -122.266129 },
  }, {
    title: 'Back in my day',
    content: <div><p>It isn't a weekday so maybe <A /> headed to somewhere more affordable... Sometimes <J /> and <A /> would trek across town to their old haunts when they were really craving some good, cheap food. In fact, a certain restaurant is still a favorite of <J />, and they head there whenever they're in town for a football game.</p></div>,
    at: 'Maybe there\'s some time for a quick order at La Burrita! Or maybe not...A must be close by now!',
    continue: 'Last stop...',
    location: { latitude: 37.867831, longitude: -122.257457 },
  }, {
    title: 'Where it All Began',
    content: <div><p>There could be one last spot where <A /> ended up. It might just be the place where <A /> and <J /> first met, all those years ago.</p>

    <p>It took a little while for their relationship to form, but once <A  /> finally got all the hints it has turned into an amazing story. There's no telling what the future might hold, but <J /> and <A /> know they'll experience every moment of it together!</p></div>,
    at: '',
    continue: 'Fin',
    location: { latitude: 37.866164, longitude: -122.254289 },
  }
]
